<template>
    <div class="result-board">
        <div class="result-board-text" v-if="$store.state.pk.loser === 'All'">
            Draw
        </div>
        <div class="result-board-text"
            v-else-if="$store.state.pk.loser === 'A' && $store.state.pk.a_id == $store.state.user.id">
            Lose
        </div>
        <div class="result-board-text"
            v-else-if="$store.state.pk.loser === 'B' && $store.state.pk.b_id == $store.state.user.id">
            Lose
        </div>
        <div class="result-board-text" v-else>
            Win
        </div>
        <div class="result-board-button">
            <el-button type="warning" size="large" @click="restart">
                <span style="color: black; font-size: 24px;">发起再战</span>
            </el-button>
        </div>

    </div>
</template>
<script setup>
// import { ref } from 'vue';
import { useStore } from 'vuex';
const store = useStore();


const restart = () => {
    store.commit("updateStatus", "matching");
    store.commit("updateLoser", "none");
    store.commit("updateOpponent", {
        username: "我的对手",
        photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
    });
}
</script>
<style scoped>
div.result-board {
    height: 30vh;
    width: 30vw;
    background-color: rgba(50, 50, 50, 0.5);
    position: absolute;
    top: 35vh;
    left: 35vw;
}

div.result-board-text {
    text-align: center;
    color: white;
    font-size: 50px;
    font-weight: 600;
    font-style: italic;
    padding-top: 5vh;
}

div.result-board-button {
    padding-top: 3vh;
    text-align: center;
}
</style>