<template>
    <div>
        <h4>空亡</h4>
        <ul>
            <li>音信稀时</li>
            <li>五行属土</li>
            <li>黄色</li>
            <li>方位中央</li>
            <li>勾陈</li>
            <li>数字：3,6,9</li>
            <li>有不吉、无果之意</li>
            <li>口诀：空亡事不祥，阴人多乖张，求财无利益，行人有灾殃，失物寻一见，官事有刑伤，病人逢暗鬼，祈解保安康。</li>
        </ul>
    </div>
</template>
<script>
</script>
<style></style>