<template>
    <div>
        <h4>大安</h4>
        <ul>
            <li>身不动时</li>
            <li>五行属木</li>
            <li>方位东</li>
            <li>青龙</li>
            <li>数字：1,5,7</li>
            <li>有静止、心安、吉祥之意</li>
            <li>口诀：大安事事昌，求谋在东方，失物去不远，宅舍保平安，行人身未动，病者主无妨，将军回田野，仔细更推详。</li>
        </ul>
    </div>
</template>
<script>
</script>
<style></style>