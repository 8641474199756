import { GameObject } from "./GameObject";
import { Snake } from "./Snake";
import { Wall } from "./Walls";

export class GameMap extends GameObject {
    constructor(ctx, parent, store) {
        super();

        this.ctx = ctx;
        this.parent = parent;
        this.L = 0;
        this.store = store;

        this.rows = 13; // 行数
        this.cols = 14; // 列数

        this.walls = []; //墙数组
        this.inner_walls_count = 40; //墙的数量

        this.snakes = [
            new Snake({ id: 0, color: "#4876EC", r: this.rows - 2, c: 1 }, this),
            new Snake({ id: 1, color: "#F94848", r: 1, c: this.cols - 2 }, this)
        ]
    }

    // 判断是否联连通 Flood fill算法
    // check_connectivity(g, sx, sy, tx, ty) {
    //     if (sx == tx && sy == ty) return true;
    //     g[sx][sy] = true;

    //     let dx = [-1, 0, 1, 0], dy = [0, 1, 0, -1];
    //     for (let i = 0; i < 4; i++) {
    //         let x = sx + dx[i], y = sy + dy[i];
    //         if (!g[x][y] && this.check_connectivity(g, x, y, tx, ty))
    //             return true;
    //     }
    //     return false;
    // }

    create_walls() {
        const g = this.store.state.pk.gamemap;

        // const g = []; // 创建一个bool数组
        // //全部初始化为false
        // for (let r = 0; r < this.rows; r++) {
        //     g[r] = [];
        //     for (let c = 0; c < this.cols; c++) {
        //         g[r][c] = false;
        //     }
        // }

        // // 给四周加上障碍物
        // for (let r = 0; r < this.rows; r++) {
        //     g[r][0] = g[r][this.cols - 1] = true;
        // }
        // for (let c = 0; c < this.cols; c++) {
        //     g[0][c] = g[this.rows - 1][c] = true;
        // }

        // // 创建随机障碍物
        // for (let i = 0; i < this.inner_walls_count / 2; i++) {
        //     for (let j = 0; j < 1000; j++) { // 循环1000次找不重复值
        //         let r = parseInt(Math.random() * this.rows);
        //         let c = parseInt(Math.random() * this.cols);

        //         if (g[r][c] || g[this.rows - 1 - r][this.cols - 1 - c]) continue; // 如果已经被占用了，就继续
        //         if (r == this.rows - 2 && c == 1 || r == 1 && c == this.cols - 2) continue; // 不能是左下角和右上角
        //         g[r][c] = g[this.rows - 1 - r][this.cols - 1 - c] = true;
        //         break;
        //     }
        // }

        // const copy_g = JSON.parse(JSON.stringify(g)); //先转换Json再解析出来， 实现复制
        // if (!this.check_connectivity(copy_g, this.rows - 2, 1, 1, this.cols - 2)) return false; // 判断是否联通

        // 创建墙
        for (let r = 0; r < this.rows; r++)
            for (let c = 0; c < this.cols; c++) {
                if (g[r][c]) {
                    this.walls.push(new Wall(r, c, this));
                }
            }
        // return true;
    }

    add_listening_events() {
        if (this.store.state.record.is_record) {
            let k = 0;
            const a_steps = this.store.state.record.a_steps;
            const b_steps = this.store.state.record.b_steps;
            const loser = this.store.state.record.record_loser;
            const [snake0, snake1] = this.snakes;
            console.log(a_steps.length)
            const interval_id = setInterval(() => {
                if (k >= a_steps.length - 1) {
                    if (loser === "All" || loser === "A") {
                        snake0.status = "die";
                    }
                    if (loser === "All" || loser === "B") {
                        snake1.status = "die";
                    }
                    clearInterval(interval_id);
                } else {
                    snake0.set_direction(parseInt(a_steps[k]));
                    snake1.set_direction(parseInt(b_steps[k]));
                }
                k++;
            }, 300);
        } else {
            this.ctx.canvas.focus(); // 聚焦

            this.ctx.canvas.addEventListener("keydown", e => {
                let d = -1
                if (e.key === "w") d = 0;
                else if (e.key === "d") d = 1;
                else if (e.key === "s") d = 2;
                else if (e.key === "a") d = 3;
                // else if (e.key === "ArrowUp") snake1.set_direction(0);
                // else if (e.key === "ArrowRight") snake1.set_direction(1);
                // else if (e.key === "ArrowDown") snake1.set_direction(2);
                // else if (e.key === "ArrowLeft") snake1.set_direction(3);

                if (d >= 0) {
                    this.store.state.pk.socket.send(JSON.stringify({
                        event: "move",
                        direction: d,
                    }));
                }
            })
        }
    }

    start() {
        this.create_walls();
        // for (let i = 0; i < 1000; i++) {
        //     if (this.create_walls())
        //         break;
        // }
        this.add_listening_events();
    }

    check_ready() { // 判断两条蛇是否都准备好下一步了
        for (const snake of this.snakes) {
            if (snake.status !== "idle") return false;
            if (snake.direction === -1) return false;
        }
        return true;
    }

    update_size() {
        // 强转为int 防止出现白缝
        this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));
        this.ctx.canvas.width = this.L * this.cols;
        this.ctx.canvas.height = this.L * this.rows;
    }

    next_step() { // 进入下一回合
        for (const snake of this.snakes) {
            snake.next_step();
        }
    }

    // check_valid(cell) { // 检查目标位置是否合法：没有撞倒墙和身体
    //     for (const wall of this.walls) {
    //         if (wall.r === cell.r && wall.c === cell.c) {
    //             return false;
    //         }

    //     }

    //     for (const snake of this.snakes) {
    //         let k = snake.cells.length;
    //         if (!snake.check_tail_increasing()) {
    //             k--;
    //         }
    //         for (let i = 0; i < k; i++) {
    //             if (snake.cells[i].r === cell.r && snake.cells[i].c === cell.c) {
    //                 return false;
    //             }

    //         }
    //     }
    //     return true;
    // }

    update() {
        this.update_size();
        if (this.check_ready()) {
            this.next_step();
        }
        this.render();
    }

    render() {
        // 画出地图
        const color_even = "#AAD751", color_odd = "#A2D149";
        for (let r = 0; r < this.rows; r++)
            for (let c = 0; c < this.cols; c++) {
                if ((r + c) % 2 == 0) {
                    this.ctx.fillStyle = color_even;
                } else {
                    this.ctx.fillStyle = color_odd;
                }
                this.ctx.fillRect(c * this.L, r * this.L, this.L, this.L);
            }
    }
}