<template>
    <div class="mb-4">
        <el-button type="primary" @click="outerVisible = true" round>
            时辰起卦
        </el-button>

        <el-dialog class="divination_title" v-model="outerVisible" title="" width="800">
            <h2 style="color:rgb(1, 16, 26);">当前是{{ ldatetime }}, 以当前时辰起卦得出</h2>
            <br>
            <span class="divination_result_text">第一次结果: {{ result.first_result }}
                <el-button type="primary" @click="firstVisible = true">
                    详情
                </el-button>
            </span>
            <br><br>
            <span class="divination_result_text">第二次结果: {{ result.second_result }}
                <el-button type="primary" @click="secondVisible = true">
                    详情
                </el-button>
            </span>
            <br><br>
            <span class="divination_result_text">第三次结果: {{ result.third_result }}
                <el-button type="primary" @click="thirdVisible = true">
                    详情
                </el-button>
            </span>
            <br><br>
            <el-dialog v-model="firstVisible" width="500" title="详情" append-to-body>
                <div v-if="DaAn_1">
                    <daan-result></daan-result>
                </div>
                <div v-if="LiuLian_1">
                    <liulian-result></liulian-result>
                </div>
                <div v-if="SuXi_1">
                    <suxi-result></suxi-result>
                </div>
                <div v-if="ChiKo_1">
                    <chiko-result></chiko-result>
                </div>
                <div v-if="XiaoJi_1">
                    <xiaoji-result></xiaoji-result>
                </div>
                <div v-if="KongWang_1">
                    <kongwang-result></kongwang-result>
                </div>
            </el-dialog>
            <el-dialog v-model="secondVisible" width="500" title="详情" append-to-body>
                <div v-if="DaAn_2">
                    <daan-result></daan-result>
                </div>
                <div v-if="LiuLian_2">
                    <liulian-result></liulian-result>
                </div>
                <div v-if="SuXi_2">
                    <suxi-result></suxi-result>
                </div>
                <div v-if="ChiKo_2">
                    <chiko-result></chiko-result>
                </div>
                <div v-if="XiaoJi_2">
                    <xiaoji-result></xiaoji-result>
                </div>
                <div v-if="KongWang_2">
                    <kongwang-result></kongwang-result>
                </div>
            </el-dialog>
            <el-dialog v-model="thirdVisible" width="500" title="详情" append-to-body>
                <div v-if="DaAn_3">
                    <span>大安详情</span>
                    <daan-result></daan-result>
                </div>
                <div v-if="LiuLian_3">
                    <liulian-result></liulian-result>
                </div>
                <div v-if="SuXi_3">
                    <suxi-result></suxi-result>
                </div>
                <div v-if="ChiKo_3">
                    <chiko-result></chiko-result>
                </div>
                <div v-if="XiaoJi_3">
                    <xiaoji-result></xiaoji-result>
                </div>
                <div v-if="KongWang_3">
                    <kongwang-result></kongwang-result>
                </div>
            </el-dialog>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="outerVisible = false">退出</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
import { LunaDate } from '@/assets/scripts/GetLunar';
import { LittleSixRen } from '@/assets/scripts/LittleSixRen';


let lunadate = new LunaDate();
var data = lunadate.getLunar();
let first = data.new_month;
let second = data.new_day;
let third = data.new_hour;
let littlesixren = new LittleSixRen();
const result = littlesixren.divanation(first, second, third);
const ldatetime = data.lunar_month_day_hour;

import { ref } from 'vue'

const outerVisible = ref(false);
const firstVisible = ref(false);
const secondVisible = ref(false);
const thirdVisible = ref(false);


import DaanResult from '@/components/divinationresult/DaanResult.vue'
import LiulianResult from '@/components/divinationresult/LiulianResult.vue'
import SuxiResult from '@/components/divinationresult/SuxiResult.vue'
import ChikoResult from '@/components/divinationresult/ChikoResult.vue'
import XiaojiResult from '@/components/divinationresult/XiaojiResult.vue'
import KongwangResult from '@/components/divinationresult/KongwangResult.vue'
var DaAn_1 = is_DaAn(result.first_result);
var LiuLian_1 = is_LiuLian(result.first_result);
var SuXi_1 = is_SuXi(result.first_result);
var ChiKo_1 = is_ChiKo(result.first_result);
var XiaoJi_1 = is_XiaoJi(result.first_result);
var KongWang_1 = is_KongWang(result.first_result);
var DaAn_2 = is_DaAn(result.second_result);
var LiuLian_2 = is_LiuLian(result.second_result);
var SuXi_2 = is_SuXi(result.second_result);
var ChiKo_2 = is_ChiKo(result.second_result);
var XiaoJi_2 = is_XiaoJi(result.second_result);
var KongWang_2 = is_KongWang(result.second_result);
var DaAn_3 = is_DaAn(result.third_result);
var LiuLian_3 = is_LiuLian(result.third_result);
var SuXi_3 = is_SuXi(result.third_result);
var ChiKo_3 = is_ChiKo(result.third_result);
var XiaoJi_3 = is_XiaoJi(result.third_result);
var KongWang_3 = is_KongWang(result.third_result);

function is_DaAn(result) {
    if (result === '大安') return true;
    return false;
}
function is_LiuLian(result) {
    if (result === '留连') return true;
    return false;
}
function is_SuXi(result) {
    if (result === '速喜') return true;
    return false;
}
function is_ChiKo(result) {
    if (result === '赤口') return true;
    return false;
}
function is_XiaoJi(result) {
    if (result === '小吉') return true;
    return false;
}
function is_KongWang(result) {
    if (result === '空亡') return true;
    return false;
}
</script>
<style>
.divination_title {
    font-size: 50px;
    color: rgb(1, 16, 26);
}

.divination_result_text {
    font-size: 30px;
    color: rgb(1, 16, 26);
}
</style>