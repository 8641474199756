<template>
    <div>
        <h4>速喜</h4>
        <ul>
            <li>人即至时</li>
            <li>五行属火</li>
            <li>红色</li>
            <li>方位南</li>
            <li>朱雀</li>
            <li>数字：3,6,9</li>
            <li>有快速、吉利、时机已到之意</li>
            <li>口诀：速喜喜来临，求财向南行，失物申未午（南、西南），逢人路上寻，官事有福德，病者无祸侵，田宅六畜吉，行人有信音。</li>
        </ul>
    </div>
</template>
<script>
</script>
<style></style>