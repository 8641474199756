<template>
    <div>
        <h4>小吉</h4>
        <ul>
            <li>人来喜时</li>
            <li>五行属木</li>
            <li>方位东</li>
            <li>六合</li>
            <li>数字：1,5,7</li>
            <li>有和合、吉利之意</li>
            <li>口诀：小吉最吉昌，路上好商量，阴人来报喜，失物在坤方（西南），行人即便至，交易甚是强，凡事皆和合，病者祈上苍。</li>
        </ul>
    </div>
</template>
<script>
</script>
<style></style>