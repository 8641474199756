<template>
    <div>
        <h4>留连</h4>
        <ul>
            <li>人未归时</li>
            <li>五行属水</li>
            <li>黑色</li>
            <li>方位北</li>
            <li>玄武</li>
            <li>数字：2,8,10</li>
            <li>有暗昧不明、延迟、纠缠、拖延、漫长之意</li>
            <li>口诀：留连事难成，求谋日未明，官事凡宜缓，去者未回程，失物南方见，急讨方心称，更须防口舌，人口且平平。</li>
        </ul>
    </div>
</template>
<script>
</script>
<style></style>