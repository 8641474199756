<template>
    <div>
        <h4>赤口</h4>
        <ul>
            <li>官事凶时</li>
            <li>五行属金</li>
            <li>白色</li>
            <li>方位西</li>
            <li>白虎</li>
            <li>数字：4,7,10</li>
            <li>有不吉、惊恐、凶险、口舌是非之意</li>
            <li>口诀：赤口主口舌，官非切宜防，失物急去寻，行人有惊慌，鸡犬（六畜）多作怪，病者出西方，更须防咀咒，诚恐染瘟皇。</li>
        </ul>
    </div>
</template>
<script>
</script>
<style></style>