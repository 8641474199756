<template>
    <div class="matchground">
        <div class="row">
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.user.photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.user.username }}
                </div>
            </div>
            <div class="col-4">
                <div class="bot-select">
                    <select v-model="select_bot" class="form-select" aria-label="Default select example">
                        <option value="-1" selected>亲自上阵</option>
                        <option v-for="bot in bots" :key="bot.id" :value="bot.id">{{ bot.title }}</option>
                    </select>
                </div>
            </div>
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.pk.opponent_photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.pk.opponent_username }}
                </div>
            </div>
            <div class="col-12">
                <div class="start-button">
                    <el-button type="warning" size="large" @click="click_match_button">
                        <span style="color: black; font-size: 24px;">{{ match_button_info }}</span>
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import $ from 'jquery';
const store = useStore();
const match_button_info = ref("开始匹配");
const click_match_button = () => {
    if (match_button_info.value === "开始匹配") {
        match_button_info.value = "取消匹配";
        store.state.pk.socket.send(JSON.stringify({
            event: "start-matching",
            bot_id: -1, // 禁用bot，还没有实现
            // bot_id: select_bot.value,
        }));
    } else {
        match_button_info.value = "开始匹配";
        store.state.pk.socket.send(JSON.stringify({
            event: "stop-matching",
        }));
    }
}

let bots = ref([]);
let select_bot = ref("-1");
const refresh_bots = () => {
    $.ajax({
        url: "http://www.xjzai1.fun/api/user/bot/getlist",
        type: "get",
        headers: {
            Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
            bots.value = resp;
        }
    });
}
refresh_bots();
</script>
<style scoped>
div.matchground {
    width: 60vw;
    height: 70vh;
    margin: 40px auto;
    margin-top: 10vh;
    background-color: rgba(50, 50, 50, 0.5);
}

div.user-photo {
    text-align: center;
    padding-top: 10vh;
}

div.user-photo>img {
    border-radius: 50%;
    width: 20vh;
}

div.user-username {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: white;
    padding-top: 2vh;
}

div.start-button {
    text-align: center;
    padding-top: 10vh;
}

div.bot-select {
    padding-top: 20vh;
}

div.bot-select>select {
    width: 60%;
    margin: 0 auto;
}
</style>