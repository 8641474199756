<template>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="第一个数字">
            <el-input type="number" v-model="first_count" placeholder="请输入第一个数" clearable />
        </el-form-item>
        <el-form-item label="第二个数字">
            <el-input type="number" v-model="second_count" placeholder="请输入第二个数" clearable />
        </el-form-item>
        <el-form-item label="第三个数字">
            <el-input type="number" v-model="third_count" placeholder="请输入第三个数" clearable />
        </el-form-item>

        <el-form-item>
            <el-button type="primary" @click="onSubmit" round>三数起卦</el-button>
        </el-form-item>
    </el-form>
    <el-dialog class="divination_title" v-model="otherVisible" title="" width="800">
        <h2 style="color:rgb(1, 16, 26);">你给出的三个数字为{{ first_count }}, {{ second_count }}, {{ third_count }} 以数字起卦得出
        </h2>
        <br>
        <span class="divination_result_text">第一次结果: {{ result.first_result }}
            <el-button type="primary" @click="firstVisible = true">
                详情
            </el-button>
        </span>
        <br><br>
        <span class="divination_result_text">第二次结果: {{ result.second_result }}
            <el-button type="primary" @click="secondVisible = true">
                详情
            </el-button>
        </span>
        <br><br>
        <span class="divination_result_text">第三次结果: {{ result.third_result }}
            <el-button type="primary" @click="thirdVisible = true">
                详情
            </el-button>
        </span>
        <br><br>
        <el-dialog v-model="firstVisible" width="500" title="详情" append-to-body>
            <div v-if="DaAn_1">
                <daan-result></daan-result>
            </div>
            <div v-if="LiuLian_1">
                <liulian-result></liulian-result>
            </div>
            <div v-if="SuXi_1">
                <suxi-result></suxi-result>
            </div>
            <div v-if="ChiKo_1">
                <chiko-result></chiko-result>
            </div>
            <div v-if="XiaoJi_1">
                <xiaoji-result></xiaoji-result>
            </div>
            <div v-if="KongWang_1">
                <kongwang-result></kongwang-result>
            </div>
        </el-dialog>
        <el-dialog v-model="secondVisible" width="500" title="详情" append-to-body>
            <div v-if="DaAn_2">
                <daan-result></daan-result>
            </div>
            <div v-if="LiuLian_2">
                <liulian-result></liulian-result>
            </div>
            <div v-if="SuXi_2">
                <suxi-result></suxi-result>
            </div>
            <div v-if="ChiKo_2">
                <chiko-result></chiko-result>
            </div>
            <div v-if="XiaoJi_2">
                <xiaoji-result></xiaoji-result>
            </div>
            <div v-if="KongWang_2">
                <kongwang-result></kongwang-result>
            </div>
        </el-dialog>
        <el-dialog v-model="thirdVisible" width="500" title="详情" append-to-body>
            <div v-if="DaAn_3">
                <span>大安详情</span>
                <daan-result></daan-result>
            </div>
            <div v-if="LiuLian_3">
                <liulian-result></liulian-result>
            </div>
            <div v-if="SuXi_3">
                <suxi-result></suxi-result>
            </div>
            <div v-if="ChiKo_3">
                <chiko-result></chiko-result>
            </div>
            <div v-if="XiaoJi_3">
                <xiaoji-result></xiaoji-result>
            </div>
            <div v-if="KongWang_3">
                <kongwang-result></kongwang-result>
            </div>
        </el-dialog>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="otherVisible = false">退出</el-button>
            </div>
        </template>
    </el-dialog>

</template>
<script setup>
import { LittleSixRen } from '@/assets/scripts/LittleSixRen';
import { ref } from 'vue'
let littlesixren = new LittleSixRen();
const firstVisible = ref(false);
const secondVisible = ref(false);
const thirdVisible = ref(false);
const otherVisible = ref(false);
const first_count = ref();
const second_count = ref();
const third_count = ref();
var result = false;
var DaAn_1 = false;
var LiuLian_1 = false;
var SuXi_1 = false;
var ChiKo_1 = false;
var XiaoJi_1 = false;
var KongWang_1 = false;
var DaAn_2 = false;
var LiuLian_2 = false;
var SuXi_2 = false;
var ChiKo_2 = false;
var XiaoJi_2 = false;
var KongWang_2 = false;
var DaAn_3 = false;
var LiuLian_3 = false;
var SuXi_3 = false;
var ChiKo_3 = false;
var XiaoJi_3 = false;
var KongWang_3 = false;
const onSubmit = () => {
    var first_num = parseInt(first_count.value);
    var second_num = parseInt(second_count.value);
    var third_num = parseInt(third_count.value);
    result = littlesixren.divanation(first_num, second_num, third_num);
    otherVisible.value = true;
    DaAn_1 = is_DaAn(result.first_result);
    LiuLian_1 = is_LiuLian(result.first_result);
    SuXi_1 = is_SuXi(result.first_result);
    ChiKo_1 = is_ChiKo(result.first_result);
    XiaoJi_1 = is_XiaoJi(result.first_result);
    KongWang_1 = is_KongWang(result.first_result);
    DaAn_2 = is_DaAn(result.second_result);
    LiuLian_2 = is_LiuLian(result.second_result);
    SuXi_2 = is_SuXi(result.second_result);
    ChiKo_2 = is_ChiKo(result.second_result);
    XiaoJi_2 = is_XiaoJi(result.second_result);
    KongWang_2 = is_KongWang(result.second_result);
    DaAn_3 = is_DaAn(result.third_result);
    LiuLian_3 = is_LiuLian(result.third_result);
    SuXi_3 = is_SuXi(result.third_result);
    ChiKo_3 = is_ChiKo(result.third_result);
    XiaoJi_3 = is_XiaoJi(result.third_result);
    KongWang_3 = is_KongWang(result.third_result);


}
function is_DaAn(result) {
    if (result === '大安') return true;
    return false;
}
function is_LiuLian(result) {
    if (result === '留连') return true;
    return false;
}
function is_SuXi(result) {
    if (result === '速喜') return true;
    return false;
}
function is_ChiKo(result) {
    if (result === '赤口') return true;
    return false;
}
function is_XiaoJi(result) {
    if (result === '小吉') return true;
    return false;
}
function is_KongWang(result) {
    if (result === '空亡') return true;
    return false;
}
import DaanResult from '@/components/divinationresult/DaanResult.vue'
import LiulianResult from '@/components/divinationresult/LiulianResult.vue'
import SuxiResult from '@/components/divinationresult/SuxiResult.vue'
import ChikoResult from '@/components/divinationresult/ChikoResult.vue'
import XiaojiResult from '@/components/divinationresult/XiaojiResult.vue'
import KongwangResult from '@/components/divinationresult/KongwangResult.vue'
</script>
<style></style>