export class LittleSixRen {
    constructor(first, second, third) {
        this.first = first;
        this.second = second;
        this.third = third;
    }

    getResult() {
        return this.divanation(this.first, this.second, this.third);
    }

    divanation(first, second, third) {
        var first_num = first % 6;
        var second_num = (first_num + second - 1) % 6;
        var third_num = (second_num + third - 1) % 6;
        var first_result = this.parse_resault(first_num);
        var second_result = this.parse_resault(second_num);
        var third_result = this.parse_resault(third_num);

        return { first_result, second_result, third_result };
    }

    parse_resault(result) {
        switch (result) {
            case 1:
                return "大安";
            case 2:
                return "留连";
            case 3:
                return "速喜";
            case 4:
                return "赤口";
            case 5:
                return "小吉";
            default:
                return "空亡";
        }
    }
}