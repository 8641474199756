<template>
    <div class="text">
        <ContentField>
            对局列表
            <table class="table table-hover" style="text-align: center">
                <thead>
                    <tr>
                        <th>A</th>
                        <th>B</th>
                        <th>对战结果</th>
                        <th>对局时间</th>
                        <th>对局回放</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" record of records" :key="record.record.id">
                        <td>
                            <img :src="record.a_photo" alt="" class="record-user-photo">
                            &nbsp;
                            <span class="record-user-username">{{ record.a_username }}</span>
                        </td>
                        <td>
                            <img :src="record.b_photo" alt="" class="record-user-photo">
                            &nbsp;
                            <span class="record-user-username">{{ record.b_username }}</span>
                        </td>
                        <td>{{ record.result }}</td>
                        <td>{{ record.record.createTime }}</td>
                        <td>
                            <el-button type="primary" style="margin-right: 10px;"
                                @click="open_record_content(record.record.id)">对局回放</el-button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation example">
                <ul class="pagination" style="float: right;">
                    <li class="page-item" @click="click_page(-2)">
                        <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number"
                        @click="click_page(page.number)">
                        <a class="page-link" href="#">{{ page.number }}</a>
                    </li>
                    <li class="page-item" @click="click_page(-1)">
                        <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </ContentField>
    </div>
</template>
<script setup>
import ContentField from '@/components/ContentField.vue'
import { useStore } from 'vuex';
import { ref } from 'vue';
import $ from 'jquery';
import router from '@/router';

const store = useStore();
let current_page = 1;
let records = ref([]);
let total_records = 0;
let pages = ref([]);

const click_page = page => {
    if (page === -2) page = current_page - 1;
    else if (page === -1) page = current_page + 1;
    let max_pages = parseInt(Math.ceil(total_records / 10));

    if (page >= 1 && page <= max_pages) {
        pull_page(page);
    }
}

const update_pages = () => {
    let max_pages = parseInt(Math.ceil(total_records / 10));
    let new_pages = [];
    for (let i = current_page - 2; i <= current_page + 2; i++) {
        if (i >= 1 && i <= max_pages) {
            new_pages.push({
                number: i,
                is_active: i === current_page ? "active" : "",
            });
        }
    }
    pages.value = new_pages;
}

console.log(total_records);

const pull_page = page => {
    current_page = page;
    $.ajax({
        url: "http://www.xjzai1.fun/api/record/getlist",
        data: {
            page,
        },
        type: "get",
        headers: {
            Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
            records.value = resp.records;
            total_records = resp.records_count;
            update_pages();
        },
        error(resp) {
            console.log(resp);
        }
    });
}
pull_page(current_page);

const stringTo2D = map => {
    let g = [];
    for (let i = 0, k = 0; i < 13; i++) {
        let line = [];
        for (let j = 0; j < 14; j++, k++) {
            if (map[k] === '0') line.push(0);
            else line.push(1);
        }
        g.push(line);
    }
    return g;
}

const open_record_content = recordId => {
    for (const record of records.value) {
        if (record.record.id === recordId) {
            store.commit("updateIsRecord", true);
            console.log(record);
            store.commit("updateGame", {
                map: stringTo2D(record.record.map),
                a_id: record.record.aId,
                a_sx: record.record.aSx,
                a_sy: record.record.aSy,
                b_id: record.record.bId,
                b_sx: record.record.bSx,
                b_sy: record.record.bSy,
            });
            store.commit("updateSteps", {
                a_steps: record.record.aSteps,
                b_steps: record.record.bSteps,
            });
            store.commit("updateRecordLoser", record.record.loser);
            router.push({
                name: "record_content",
                params: {
                    recordId
                }
            })
            break;
        }
    }
}
</script>
<style scoped>
img.record-user-photo {
    width: 4vh;
    border-radius: 50%;
}
</style>