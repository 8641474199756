import $ from 'jquery';

export default {
    state: {
        id: "",
        username: "",
        photo: "",
        token: "",
        id_login: false,
        pulling_info: true, // 是否正在拉取信息
    },
    getters: {
    },
    mutations: {
        updateUser(state, user) {
            state.id = user.id;
            state.username = user.username;
            state.photo = user.photo;
            state.is_login = user.is_login;
        },
        updateToken(state, token) {
            state.token = token;
        },
        logout(state) {
            state.id = '';
            state.username = '';
            state.photo = '';
            state.token = '';
            state.is_login = false;
        },
        updatePullingInfo(state, pulling_info) {
            state.pulling_info = pulling_info;
        },
        updatePhoto(state, photo) {
            state.photo = photo;
        },
    },
    actions: {
        login(context, data) {
            $.ajax({
                url: "http://www.xjzai1.fun/api/user/account/token",
                type: 'post',
                contentType: "application/json;charset=UTF-8",
                data: JSON.stringify({
                    "username": data.username,
                    "password": data.password,
                }),
                success(resp) {
                    if (resp.error_message === "success") {
                        localStorage.setItem("jwt_token", resp.token); //刷新后不退出
                        context.commit("updateToken", resp.token);
                        data.success(resp);
                    } else {
                        data.error(resp);
                    }
                },
                error(resp) {
                    data.error(resp);
                }
            })
            // axios.post("http://localhost:8081/user/account/token", { username: Date.username, password: Date.password }).then((result) => {
            //     if (result.error_message === "success") {
            //         context.commit("updataToken", result.token)
            //         data.success(result);
            //     } else {
            //         data.error(result);
            //     }
            // });
        },
        getinfo(context, data) {
            $.ajax({
                url: "http://www.xjzai1.fun/api/user/account/info",
                type: "get",
                headers: {
                    Authorization: "Bearer " + context.state.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        context.commit("updateUser", {
                            ...resp,
                            is_login: true,
                        });
                        data.success(resp);
                    } else {
                        data.error(resp);
                    }
                },
                error(resp) {
                    data.error(resp);
                }
            });
        },
        logout(context) {
            localStorage.removeItem("jwt_token");
            context.commit("logout");
        }
    },
    modules: {
    }
}