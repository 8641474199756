<template>
    <div class="text">
        <PLayGround v-if="$store.state.pk.status === 'playing'"></PLayGround>
        <MatchGround v-if="$store.state.pk.status === 'matching'"></MatchGround>
        <ResultBoard v-if="$store.state.pk.loser !== 'none'"></ResultBoard>
        <div class="user-color-left"
            v-if="$store.state.pk.status === 'playing' && $store.state.user.id == $store.state.pk.a_id">当前颜色：蓝色</div>
        <div class="user-color-right"
            v-if="$store.state.pk.status === 'playing' && $store.state.user.id == $store.state.pk.b_id">当前颜色：红色</div>
    </div>
</template>
<script setup>
import MatchGround from '@/components/MatchGround.vue';
import PLayGround from '@/components/PLayGround.vue'
import ResultBoard from '@/components/ResultBoard.vue'
import { onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const socketUrl = `ws://www.xjzai1.fun/websocket/${store.state.user.token}`;

store.commit("updateLoser", "none");
store.commit("updateIsRecord", false);

let socket = null;
onMounted(() => {
    store.commit("updateOpponent", {
        username: "我的对手",
        photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
    });
    socket = new WebSocket(socketUrl);

    socket.onopen = () => {
        console.log("connected!");
        store.commit("updateSocket", socket);
    };

    socket.onmessage = msg => {
        console.log("received!");
        const data = JSON.parse(msg.data);
        if (data.event === "start-matching") {
            store.commit("updateOpponent", {
                username: data.opponent_username,
                photo: data.opponent_photo,
            });
            setTimeout(() => {
                store.commit("updateStatus", "playing");
            }, 2000);
            store.commit("updateGame", data.game);
        } else if (data.event === "move") {
            console.log(data);
            const game = store.state.pk.game_object;
            const [snake0, snake1] = game.snakes;
            snake0.set_direction(data.a_direction);
            snake1.set_direction(data.b_direction);
        } else if (data.event === "result") {
            console.log(data);
            const game = store.state.pk.game_object;
            const [snake0, snake1] = game.snakes;

            if (data.loser === "All" || data.loser === "A") {
                snake0.status = "die";
            }
            if (data.loser === "All" || data.loser === "B") {
                snake1.status = "die";
            }
            store.commit("updateLoser", data.loser);
        }
        console.log(data);
    };

    socket.onclose = () => {
        console.log("disconnected!");
    };
});

onUnmounted(() => {
    socket.close();
    store.commit("updateStatus", "matching");
});


</script>
<style scoped>
div.user-color-left {
    text-align: center;
    color: blue;
    font-size: 30px;
    font-weight: 600;
}

div.user-color-right {
    text-align: center;
    color: red;
    font-size: 30px;
    font-weight: 600;
}
</style>