import { createRouter, createWebHistory } from 'vue-router'
import PkindexView from '../views/pk/PkindexView.vue'
import RanklistindexView from '../views/ranklist/RanklistindexView.vue'
import RecordView from '../views/record/RecordView.vue'
import RecordContentView from '@/views/record/RecordContentView.vue'
import UserbotindexView from '../views/user/bot/UserbotindexView.vue'
import NotFound from '../views/error/NotFound.vue'
import DivinationView from '../views/divination/DivinationView.vue'
import UserAccountLoginView from '@/views/user/account/UserAccountLoginView.vue'
import UserAccountRegisterView from '@/views/user/account/UserAccountRegisterView.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/pk',
    meta: {
      requestAuth: true, // 需要授权
    }
  },
  {
    path: '/user/account/register',
    name: 'user_account_register_index',
    component: UserAccountRegisterView
  },
  {
    path: '/user/account/login',
    name: 'user_account_login_index',
    component: UserAccountLoginView
  },
  {
    path: '/divination',
    name: 'divination_index',
    component: DivinationView
  },
  {
    path: '/pk',
    name: 'pk_index',
    component: PkindexView,
    meta: {
      requestAuth: true, // 需要授权
    }
  },
  {
    path: '/ranklist',
    name: 'ranklist_index',
    component: RanklistindexView,
    meta: {
      requestAuth: true, // 需要授权
    }
  },
  {
    path: '/record',
    name: 'record_index',
    component: RecordView,
    meta: {
      requestAuth: true, // 需要授权
    }
  },
  {
    path: '/record/:recordId', // :可以加参数
    name: 'record_content',
    component: RecordContentView,
    meta: {
      requestAuth: true, // 需要授权
    }
  },
  {
    path: '/user/bot',
    name: 'user_bot_index',
    component: UserbotindexView,
    meta: {
      requestAuth: true, // 需要授权
    }
  },
  {
    path: '/404',
    name: '404',
    component: NotFound
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requestAuth && !store.state.user.is_login) {
    next({ name: "user_account_login_index" });
  } else {
    next();
  }
})

export default router
