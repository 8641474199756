<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-body">
                        <img :src="$store.state.user.photo" alt="" style="width: 100%;">
                    </div>
                    <div style="text-align: center;">
                        <el-button type="primary" style="margin-bottom: 15px;" data-bs-toggle="modal"
                            data-bs-target="#update-images" @click="chan">
                            更改头像</el-button>
                        <div class="modal fade" id="update-images" tabindex="-1">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <form action="">
                                            <div class="mb-3">
                                                <label for="update-bot-title" class="form-label">新头像链接</label>
                                                <input v-model="photo" type="text" class="form-control"
                                                    id="update-image" placeholder="请输入头像链接">
                                            </div>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            data-bs-dismiss="modal">取消</button>
                                        <button type="button" class="btn btn-primary" @click="update_image">确认</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-header">
                        <span style="font-size: 130%;">我的Bot</span>
                        <el-button type="primary" class="float-end" data-bs-toggle="modal"
                            data-bs-target="#add-bot">创建Bot</el-button>
                        <div class="modal fade  modal-xl" id="add-bot" tabindex="-1">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title fs-5" id="add-bot-label">创建Bot</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <form>
                                            <div class="mb-3">
                                                <label for="add-bot-title" class="form-label">名称</label>
                                                <input v-model="botadd.title" type="text" class="form-control"
                                                    id="add-bot-title" placeholder="请输入bot名称">
                                            </div>
                                            <div class="mb-3">
                                                <label for="add-bot-description" class="form-label">简介</label>
                                                <textarea v-model="botadd.description" type="text" class="form-control"
                                                    rows="3" id="add-bot-description" placeholder="请输入bot简介"></textarea>
                                            </div>
                                            <div class="mb-3">
                                                <label for="add-bot-content" class="form-label">代码内容</label>
                                                <VAceEditor v-model:value="botadd.content" @init="editorInit"
                                                    lang="c_cpp" theme="textmate" style="height: 300px" />
                                            </div>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <div class="error-message">{{ botadd.error_message }}</div>
                                        <button type="button" class="btn btn-primary" @click="add_bot">创建</button>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                                            @click="remove_message">取消</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Bot名称</th>
                                    <th>创建时间</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="bot of bots" :key="bot.id">
                                    <td>{{ bot.title }}</td>
                                    <td>{{ bot.createtime }}</td>
                                    <td>
                                        <el-button type="info" style="margin-right: 10px;" data-bs-toggle="modal"
                                            :data-bs-target="'#update-bot-' + bot.id">编辑</el-button>
                                        <el-button type="danger" data-bs-toggle="modal"
                                            :data-bs-target="'#delete-sure-' + bot.id">删除</el-button>

                                        <!-- update对话框 -->
                                        <div class="modal fade  modal-xl" :id="'update-bot-' + bot.id" tabindex="-1">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title fs-5" id="update-bot-label">创建Bot</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <form>
                                                            <div class="mb-3">
                                                                <label for="update-bot-title"
                                                                    class="form-label">名称</label>
                                                                <input v-model="bot.title" type="text"
                                                                    class="form-control" id="update-bot-title"
                                                                    placeholder="请输入bot名称">
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="update-bot-description"
                                                                    class="form-label">简介</label>
                                                                <textarea v-model="bot.description" type="text"
                                                                    class="form-control" rows="3"
                                                                    id="update-bot-description"
                                                                    placeholder="请输入bot简介"></textarea>
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="update-bot-content"
                                                                    class="form-label">代码内容</label>
                                                                <VAceEditor v-model:value="bot.content"
                                                                    @init="editorInit" lang="c_cpp" theme="textmate"
                                                                    style="height: 300px" />
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <div class="error-message">{{ bot.error_message }}</div>
                                                        <button type="button" class="btn btn-primary"
                                                            @click="update_bot(bot)">保存修改</button>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal">取消</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 删除确认框 -->
                                        <div class="modal fade" :id="'delete-sure-' + bot.id" tabindex="-1">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5">确认
                                                        </h1>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        您确定要删除吗？
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal">取消</button>
                                                        <button type="button" class="btn btn-primary"
                                                            @click="remove_bot(bot)">确认删除</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, reactive } from 'vue';
import $ from 'jquery';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap/dist/js/bootstrap';
const store = useStore();
const bots = ref([]);
//获取
const refresh_bots = () => {
    $.ajax({
        url: "http://www.xjzai1.fun/api/user/bot/getlist",
        type: "get",
        headers: {
            Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
            bots.value = resp;
        }
    });
}
refresh_bots();

//添加
const remove_message = () => {
    botadd.error_message = "";
}
const botadd = reactive({
    title: "",
    description: "",
    content: "",
    error_message: "",
})
const add_bot = () => {
    botadd.error_message = "";
    $.ajax({
        url: "http://www.xjzai1.fun/api/user/bot/add",
        type: "post",
        contentType: "application/json;charset=UTF-8",
        data: JSON.stringify({
            title: botadd.title,
            description: botadd.description,
            content: botadd.content,
        }),
        headers: {
            Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
            if (resp.error_message === "success~") {
                botadd.title = "";
                botadd.description = "";
                botadd.content = "";
                botadd.error_message = "";
                Modal.getInstance("#add-bot").hide();
                refresh_bots();
            } else {
                botadd.error_message = resp.error_message;
            }
        }
    });
}

//删除
const remove_bot = (bot) => {
    $.ajax({
        url: "http://www.xjzai1.fun/api/user/bot/remove",
        type: "delete",
        contentType: "application/json;charset=UTF-8",
        data: JSON.stringify({
            bot_id: bot.id,
        }),
        headers: {
            Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
            if (resp.error_message === "success~") {
                Modal.getInstance("#delete-sure-" + bot.id).hide();
                refresh_bots();
            }
        }
    });
}


//修改bot
const update_bot = (bot) => {
    botadd.error_message = "";
    $.ajax({
        url: "http://www.xjzai1.fun/api/user/bot/update",
        type: "put",
        contentType: "application/json;charset=UTF-8",
        data: JSON.stringify({
            bot_id: bot.id,
            title: bot.title,
            description: bot.description,
            content: bot.content,
        }),
        headers: {
            Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
            if (resp.error_message === "success~") {
                Modal.getInstance("#update-bot-" + bot.id).hide();
                refresh_bots();
            } else {
                botadd.error_message = resp.error_message;
            }
        }
    });
}
// 更改头像
let photo = store.state.user.photo;

const update_image = () => {
    $.ajax({
        url: "http://www.xjzai1.fun/api/user/update",
        type: "put",
        contentType: "application/json;charset=UTF-8",
        data: JSON.stringify({
            photo: photo,
        }),
        headers: {
            Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
            if (resp.error_message === "success~") {
                Modal.getInstance("#update-images").hide();
                refresh_photo(resp.photo);
            }
        }
    });
}

const refresh_photo = (photo) => {
    store.commit("updatePhoto", photo);
}


//编辑器
//需要vue3-ace-editor和ace-builds依赖
import { VAceEditor } from 'vue3-ace-editor';
import ace from 'ace-builds';
ace.config.set(
    "basePath",
    "https://cdn.jsdelivr.net/npm/ace-builds@" + require('ace-builds').version + "/src-noconflict/")

</script>
<style scoped>
div.error-message {
    color: red;
}
</style>